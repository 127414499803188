import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import { _t, getCookie } from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ManageSidebar from "../ManageSidebar";
import { SettingsContext } from "../../../../../contexts/Settings";

const AllRecipes = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, setLoading, dataPaginating } = useContext(SettingsContext);
  const [allRecipes, setAllRecipes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const getAllRecipes = () => {
    setLoading(true);
    const url = `${BASE_URL}/settings/get-all-recipes?page=${currentPage}`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        const data = res.data;
        setAllRecipes(data.data);
        setLastPage(data.last_page);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  //delete confirmation modal 
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteRecipe(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  // delete recipe
  const handleDeleteRecipe = (id) => {
  setLoading(true);
  const url = `${BASE_URL}/settings/delete-recipe/${id}`;
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
      const data = res.data.data;
      console.log(data);
      setAllRecipes(data.data);
      setLastPage(data.last_page);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  // set pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllRecipes();
  }, [currentPage]);

  return (
    <>
      <Helmet>
        <title>{_t(t("All Recipe"))}</title>
      </Helmet>

      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>

            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    <div className={`${dataPaginating && "loading"}`}></div>

                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("All recipe"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>{_t(t("Recipe Name"))}</th>
                              <th>{_t(t("Instructions"))}</th>
                              <th>{_t(t("View"))}</th>
                              {/* <th>Edit</th> */}
                              <th>{_t(t("Delete"))}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allRecipes && allRecipes?.length > 0 ? (
                              allRecipes.map((rs) => (
                                <tr key={rs.id}>
                                  <td>{rs.name}</td>
                                  <td>{rs.instructions}</td>
                                  <td>
                                    <Link
                                      className="text-decoration-none badge btn-dark"
                                      to={`/dashboard/manage/food/show-single-recipe/${rs.id}`}
                                    >
                                      {_t(t("View"))}
                                    </Link>
                                  </td>
                                  {/* <td>
                                    <Link
                                      className="text-decoration-none badge  btn-warning"
                                      to={`/dashboard/manage/food/edit-recipe-builder/${rs.id}`}
                                    >
                                      Edit
                                    </Link>
                                  </td> */}
                                  <td>
                                    <a
                                      onClick={() =>
                                        handleDeleteConfirmation(rs.id)
                                      }
                                      className="text-decoration-none badge  btn-danger"
                                      href="#"
                                    >
                                     {_t(t("Delete"))}
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <h4>{_t(t("No recipe added yet!"))}</h4>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                           {_t(t("Previous"))}
                          </button>
                        </li>
                        {[...Array(lastPage)].map((_, index) => (
                          <li
                            key={index + 1}
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            currentPage === lastPage ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            {_t(t("Next"))}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AllRecipes;
