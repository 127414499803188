import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  _t,
  currencySymbolLeft,
  currencySymbolRight,
  getCookie,
} from "../../../functions/Functions";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import { toast } from "react-toastify";

export default function CheckoutPage({ location }) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  // console.log("check props ", location);

  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    if (location.state === undefined || !location.state) {
      history.push("/");
    }
    // const { orderDetails } = location.state;
    setOrderDetails(location.state?.orderDetails);
  }, [history]);

  //handle changes
  const handleChange = (e) => {
    e.preventDefault();
    setOrderDetails({
      ...orderDetails,
      [e.target.name]: e.target.value,
    });
  };

  //HANDLE PAYMENT
  //submit order cod
  const handleOrderSubmit = (e) => {
    setOrderDetails({
      ...orderDetails,
      uploading: true,
    });

    e.preventDefault();
    let url = BASE_URL + "/website/order";
    let formData = orderDetails;
    formData.subTotal = location.state && location.state?.total;
    formData.vat = location.state && location.state?.vat;
    formData.delivery_charge = location.state && location.state?.deliveryCharge;

    return axios
      .post(url, orderDetails, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
           setOrderDetails({
             ...orderDetails,
             uploading: false,
           });
        if (res.data !== "ended") {
        
          toast.success(`${_t(t("Your order has been placed"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
         
        }
      })
      .catch((err) => {
         setOrderDetails({
           ...orderDetails,
           uploading: false,
         });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  return (
    <div className="container" style={{ marginTop: "80px" }}>
      <div className="row">
        {/* cart items */}
        <div className="col-md-4 order-md-2 mb-4">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-dark">Your cart items</span>
            <span className="badge badge-secondary badge-pill">3</span>
          </h4>
          <ul className="list-group mb-3">
            {orderDetails &&
              orderDetails?.items.map((item, idx) => (
                <li
                  key={idx}
                  className="list-group-item d-flex justify-content-between lh-condensed"
                >
                  <div>
                    <span className="my-0 text-capitalize">
                      {item.item.name} ({item.quantity})
                    </span>
                  </div>
                  <span className="text-muted">
                    {currencySymbolLeft()}
                    {item.subTotal}
                    {currencySymbolRight()}
                  </span>
                </li>
              ))}
            <hr />

            <li className="list-group-item d-flex justify-content-between">
              <span>Subtotal </span>
              <strong>
                {currencySymbolLeft()}
                {location.state && location.state?.subtotal}
                {currencySymbolRight()}
              </strong>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>Vat </span>
              <strong>
                {currencySymbolLeft()}
                {location.state && location.state?.vat}
                {currencySymbolRight()}
              </strong>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>{_t(t("Delivery charge"))} </span>
              <strong>
                {currencySymbolLeft()}
                {location.state && location.state?.deliveryCharge}
                {currencySymbolRight()}
              </strong>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>Total </span>
              <strong>
                {currencySymbolLeft()}
                {location.state && location.state?.total}
                {currencySymbolRight()}
              </strong>
            </li>
          </ul>
        </div>

        {/* checkout from */}
        <div className="col-md-8 order-md-1">
          <h4 className="mb-3">Delivery & Personal Details</h4>
          <form
            onSubmit={handleOrderSubmit}
            className="needs-validation"
            novalidate=""
          >
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="note">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="note"
                  placeholder=""
                  disabled
                  value={orderDetails && orderDetails?.name}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="phone">Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder=""
                  name="phn_no"
                  value={orderDetails && orderDetails?.phn_no}
                  onChange={handleChange}
                  required=""
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="address">Note to Ride</label>
              <input
                type="text"
                className="form-control"
                id="address"
                onChange={handleChange}
                name="note"
                value={orderDetails && orderDetails?.note}
                placeholder="1234 Main St"
                required=""
              />
              <div className="invalid-feedback">
                Please enter your shipping address.
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="address">Address</label>
              <textarea
                onChange={handleChange}
                rows="3"
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={orderDetails && orderDetails?.address}
                placeholder="1234 Main St"
                required=""
              ></textarea>
            </div>
            <hr className="mb-4" />
            <button
              className="btn btn-primary text-capitalize btn-lg btn-block"
              type="submit"
            >
              place order
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
